import React from 'react';
import { animated, useTrail } from 'react-spring';

import { Background } from '@components/Background';
import { Wrapper } from '@components/Wrapper';
import { Reason } from '@components/Why/Reason';
import { HeaderTitle } from '@components/HeaderTitle';
import { AnimatedSvg } from '@components/AnimatedSvg';

import { COLORS, animationEffects } from '@constants';

import { useBreakpoints } from '@utils/hooks';

import { reasons } from '@data';

import styles from './style.module.scss';

export const Why = () => {
  const { isLg } = useBreakpoints();

  const trail = useTrail(reasons.length, animationEffects.fadeIn);

  return (
    <Background left={isLg} bg={COLORS.transparent}>
      <Wrapper isHomePage>
        <div className={styles.root} id="why">
          <div className={styles.left}>
            <HeaderTitle color="#fff">
              <span>
                Expertise <br />
                that works <br />
                for you
              </span>
            </HeaderTitle>
          </div>
          <div className={styles.right}>
            <div className={styles.itemsWrapper}>
              {isLg &&
                trail.map((props, index) => {
                  const { title, icon } = reasons[index];
                  return (
                    <animated.div key={title} className={styles.item} style={props}>
                      <div className={styles.image}>
                        <AnimatedSvg Icon={icon}>{icon}</AnimatedSvg>
                      </div>

                      <h1 className={`H4 ${styles.itemTitle}`}>{title}</h1>
                    </animated.div>
                  );
                })}

              {!isLg && reasons.map(reason => <Reason key={reason.title} reason={reason} />)}
            </div>
          </div>
        </div>
      </Wrapper>
    </Background>
  );
};
