import React, { useState, useEffect } from 'react';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import PropTypes from 'prop-types';

import Arrow from '@images/componentSvg/arrow.svg';

import { addClass } from '@utils';

import styles from './style.module.scss';

export const Slider = ({
  pagesCount,
  slides,
  mobileOnly = false,
  renderContent,
  itemsPerPage = 1,
  withLine = false,
  isBlack = true,
  arrowsBottom = false
}) => {
  const [active, setActive] = useState(0);
  const [currentItems, setCurrentItems] = useState([...slides].slice(0, 3));

  useEffect(() => {
    const offset = active * itemsPerPage;
    setCurrentItems(slides.slice(offset, offset + itemsPerPage));
  }, [active]);

  const handleLeft = () => {
    setActive(active => (active === 0 ? pagesCount - 1 : active - 1));
  };

  const handleRight = () => {
    setActive(active => (active === pagesCount - 1 ? 0 : active + 1));
  };

  return (
    <div className={styles.root}>
      <div className={styles.smContent}>
        <ReactScrollWheelHandler leftHandler={handleLeft} rightHandler={handleRight}>
          <div className={styles.itemsWrapper}>
            {currentItems.map(renderContent)}

            {withLine && <hr className={styles.line} />}
          </div>
        </ReactScrollWheelHandler>

        <div className={styles.paginatorWrapper}>
          <div className={addClass(styles.circle, styles.circleActive, active === 0)} />
          <div className={addClass(styles.circle, styles.circleActive, active === 1)} />
          <div className={addClass(styles.circle, styles.circleActive, active === 2)} />
          {pagesCount === 4 && (
            <div className={addClass(styles.circle, styles.circleActive, active === 4)} />
          )}
          {pagesCount === 5 && (
            <div className={addClass(styles.circle, styles.circleActive, active === 5)} />
          )}
        </div>
      </div>

      {!mobileOnly && <div className={styles.mdContent}>{currentItems.map(renderContent)}</div>}
      <div className={addClass(styles.arrowsWrapper, styles.bottom, arrowsBottom)}>
        <Arrow onClick={handleLeft} className={addClass(styles.arrow, styles.black, styles.left, isBlack)} />
        <Arrow onClick={handleRight} className={addClass(styles.right, styles.arrow, styles.black, isBlack)} />
      </div>
    </div>
  );
};

Slider.propTypes = {
  pagesCount: PropTypes.number,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  mobileOnly: PropTypes.bool,
  renderContent: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number,
  withLine: PropTypes.bool,
  isBlack: PropTypes.bool,
  arrowsBottom: PropTypes.bool
};
