import React from 'react';

import { ContactModal } from '@components/ContactModal';
import { animated } from 'react-spring';
import { Background } from '@components/Background';
import { HeaderTitle } from '@components/HeaderTitle';
import { AnimatedText } from '@components/AnimatedText';
const backgroundUrl = './background.jpg';

import { COLORS } from '@constants';

import { useObserver } from '@utils/hooks/useObserver';

import styles from './style.module.scss';

import { useMobileFadeIn } from '@utils/hooks/useAnimation';

export const Intro = () => {
  const [topRef, isTopVisible] = useObserver({ threshold: 0.7 });

  const fadeIn = useMobileFadeIn(isTopVisible, 0);

  return (
    <Background bg={COLORS.transparent} bgUrl={backgroundUrl}>
      <div id="home" className={styles.wrapper}>
        <div className={styles.left} ref={topRef}>
          <HeaderTitle className={styles.heading} color="#fff">
            <span>You tell the story, <br /> we add the expertise</span>
          </HeaderTitle>

          <animated.div style={fadeIn} className={styles.content}>
            <p className={styles.paragraph}>
              <AnimatedText>
                We are here to help you reimagine your business through a digital lens. Our software engineering expertise combined with our IT consulting, design thinking, and digital marketing capabilities provide real business value to our customers.
              </AnimatedText>
            </p>
            <div className={styles.modal}>
              <ContactModal />
            </div>
          </animated.div>
        </div>
      </div>
    </Background>
  );
};
