import audit from '@images/componentSvg/services/audit.svg';
import game from '@images/componentSvg/services/game.svg';
import media from '@images/componentSvg/services/media.svg';
import marketing from '@images/componentSvg/services/marketing.svg';

import create from '@images/componentSvg/why/create.svg';
import grow from '@images/componentSvg/why/grow.svg';
import transparency from '@images/componentSvg/why/transparency.svg';
import deliver from '@images/componentSvg/why/deliver.svg';

export const footerLinks = [
  {
    label: 'Facebook',
    short: 'Fb',
    url: 'https://www.facebook.com/jmindsystems/'
  },
  {
    label: 'Linkedin',
    short: 'in',
    url: 'https://www.linkedin.com/company/jmind'
  },
  {
    label: 'Clutch',
    short: 'Cl',
    url: 'https://clutch.co/profile/jmindsystems'
  }
];

export const services = [
  {
    icon: game,
    title: 'IT audit & consulting',
    content: 'Help companies provide a close view into an organization’s information security posture, risk management, controls, and compliance with security requirements. Audits are designed to identify information security gaps so that the organization can take steps to reduce risks and continually enhance their controls, processes and procedures.'
  },
  {
    icon: audit,
    title: 'Software development',
    content: 'Having a strong product development expertise, We eager to offer a wide range SaaS solutions for our clients worldwide, from startups to establisht companies. Our capabilities cover everything from wireframes and MVP to mobile apps development.'
  },
  {
    icon: media,
    title: `Media content`,
    content: 'It takes talented content marketers and a strategic B2B content creation plan to define that brand, ' +
      'distinguish it from the competition.',
  },
  {
    icon: marketing,
    title: 'Marketing Support for your Product',
    content: 'Help your product into the world with all the marketing tricks',
  }
];

export const references = [
  {
    image: require('@images/references/weplay.png'),
    name: 'WePlay Esports',
    position: '',
    content: 'WePlay Esports is a leading esports media holding with key offices in the USA, China, and Ukraine. The holding combines the best practices of esports, media, and entertainment fields to provide cutting-edge products and services worldwide.'
  },
  {
    image: require('@images/references/devario.png'),
    name: 'DEVARIO',
    position: '',
    content: 'Devario is an international company that operates mostly in Europe. We develop and sale different projects in the IT sphere. We have multiple branches in our activity, including developing and promoting websites, purchasing and selling traffic, trading in computer and electronic equipment (personal computers, laptops, peripherals).'
  },
  {
    image: require('@images/references/Innova-techs.png'),
    name: 'Innova Techs',
    position: '',
    content: 'Innova-techs is a software development company by an excellent team of experienced software engineers and developers. We offer professional services in the field of custom software development for various platforms with highly qualified and dedicated staff. ',
  },
  {
    image: require('@images/references/saptage.png'),
    name: 'Saptage',
    position: '',
    content: 'Saptage is Media buro and Event Hub, specialized in film production, worldwide marketing research and solutions, media relations, above and below the line activities and events management.',
  },
];

export const vacancies = [
  {
    name: 'Sales & Marketing',
    content: 'Junior Commercial Operations Coordinator',
    linkUrl: 'mailto:contact@jssportainment.com'
  },
  {
    name: 'Web Engineering',
    content: 'Java Engineer',
    linkUrl: 'mailto:contact@jssportainment.com'
  },
  {
    name: 'Sales & Marketing ',
    content: 'Junior Marketing Specialist, DACH (part-time)',
    linkUrl: 'mailto:contact@jssportainment.com'
  }
];

export const stack = [
  {
    name: 'kubernetes',
    image: require('@images/stack/kubernetes.svg'),
    width: '120px',
    height: '21px'
  },
  {
    name: 'aws',
    image: require('@images/stack/aws.svg'),
    width: '119px',
    height: '71px'
  },
  {
    name: 'google cloud',
    image: require('@images/stack/google_cloud.svg'),
    width: '120px',
    height: '116px'
  },
  {
    name: 'react',
    image: require('@images/stack/react.svg'),
    width: '120px',
    height: '45px'
  },
  {
    name: 'node',
    image: require('@images/stack/node_js.svg'),
    width: '120px',
    height: '74px'
  },
];

export const reasons = [
  {
    icon: create,
    title: 'We create value'
  },
  {
    icon: grow,
    title: 'You grow with us'
  },
  {
    icon: transparency,
    title: 'You get full transparency'
  },
  {
    icon: deliver,
    title: 'We deliver expertise'
  }
];
