import { useSpring, config } from 'react-spring';
import { animationEffects } from '@constants';

export const useAnimation = (effect = {}, delay) => {
  return useSpring({ ...effect, delay, config: config.default });
};

export const useFadeIn = delay => useAnimation(animationEffects.fadeIn, delay);

export const useAppearLeft = delay => useAnimation(animationEffects.appearLeft, delay);

export const useAppearRight = delay => useAnimation(animationEffects.appearRight, delay);

const getMobileAnimationEffect = (effect, isVisible, delay, config = 'default') => {
  const getTernary = (next, prev) => {
    return Object.values(next).map((value, index) => () => (isVisible ? value : Object.values(prev)[index]));
  };

  const getEffect = effect => {
    const values = getTernary(effect.to, effect.from);
    const keys = Object.keys(effect.to);

    let to = keys.reduce((object, key, value) => {
      return { ...object, [key]: values[value]() };
    }, {});

    return {
      ...effect,
      to,
      config: config[config],
      delay
    };
  };

  const resultEffect = getEffect(effect);

  return useSpring(resultEffect);
};

export const useMobileFadeIn = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.fadeIn, isVisible, delay);

export const useMobileFadeInDown = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.fadeInDown, isVisible, delay, 'slow');

export const useParallaxUp = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.parallaxUp, isVisible, delay, 'slow');

export const useSlideUp = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.slideUp, isVisible, delay, 'slow');

export const useAbsoluteParallaxUp = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.absoluteParallaxUp, isVisible, delay);

export const useMobileAppearTop = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.appearTop, isVisible, delay);

export const useMobileAppearLeft = (isVisible, delay) =>
  getMobileAnimationEffect(animationEffects.appearLeft, isVisible, delay);
