import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';

import styles from './style.module.scss';

import { useObserver } from '@utils/hooks/useObserver';

export const AnimatedSvg = ({ className, Icon }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.5 });
  return (
    <div className={classNames(
      styles.wrapper,
      className,
    )} ref={ref}>
      <Icon className={isVisible ? styles.icon : ''} />
    </div>
  );
};

AnimatedSvg.propTypes = {
  Icon: PropTypes.func.isRequired,
  className: PropTypes.string
};
