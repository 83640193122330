import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

export const Background = ({ children, bgUrl, bg, right, left, bottom, text }) => {
  return (
    <div
      className={`${right ? styles.rightGradient : ''} ${left ? styles.leftGradient : ''} ${
        bottom ? styles.bottomGradient : ''
      } ${styles.root}`}
      style={{ backgroundImage: bgUrl ? `url('${bgUrl}')` : null, backgroundSize: bgUrl ? 'cover' : null, backgroundPosition: bgUrl ? 'right' : null, backgroundColor: bg, color: text }}
    >
      {children}
    </div>
  );
};

Background.propTypes = {
  children: PropTypes.element,
  bgUrl: PropTypes.string,
  bg: PropTypes.string,
  right: PropTypes.bool,
  left: PropTypes.bool,
  bottom: PropTypes.bool,
  text: PropTypes.string
};
