import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { animated } from 'react-spring';

import { Background } from '@components/Background';
import { Wrapper } from '@components/Wrapper';
import { ContactModal } from '@components/ContactModal';
import { HeaderTitle } from '@components/HeaderTitle';

import locationIcon from '@images/getInTouch/location.svg';

import { useParallaxUp, useMobileFadeInDown } from '@utils/hooks/useAnimation';
import { useObserver } from '@utils/hooks/useObserver';

export const GetInTouch = ({ isMapShown }) => {
  const [bottomRef, isBottomVisible] = useObserver({ threshold: 0.5 });

  const fadeInDown = useMobileFadeInDown(isBottomVisible, 200);
  const parallaxUp = useParallaxUp(isMapShown, 0);

  return (
    <div>
      <Background bg="white">
        <Wrapper isHomePage>
          <animated.div className={styles.root} style={parallaxUp}>
            <div className={styles.titleWrapper}>
              <HeaderTitle color="#000">
                <span>Get in touch</span>
              </HeaderTitle>
            </div>
            <animated.div ref={bottomRef} className={styles.content} style={fadeInDown}>
              <div className={styles.itemsWrapper}>
                <div className={styles.itemTitle}>Our contacts</div>
                <div className={styles.itemDescription}>
                  <img className={styles.image} src={locationIcon} alt="location" />
                  Vasili Michailidi,9 3026, Limasol, Cyprus
                </div>
              </div>
              <div className={styles.line} />
              <div className={styles.itemsWrapper}>
                <div className={styles.c}>
                  <a className={styles.p} href="mailto:contact@jssportainment.com">
                    contact@jssportainment.com
                  </a>
                </div>
              </div>
              <div className={styles.modalWrapper}>
                <ContactModal />
              </div>
            </animated.div>
          </animated.div>
        </Wrapper>
      </Background>
    </div>
  );
};

GetInTouch.propTypes = {
  isMapShown: PropTypes.bool,
  setIsScrollDisabled: PropTypes.func
};

GetInTouch.defaultProps = {
  isMapShown: false,
  setIsScrollDisabled: () => null
};
