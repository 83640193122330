import React from 'react';
import PropTypes from 'prop-types';

import { AnimatedText } from '@components/AnimatedText';

import styles from './style.module.scss';

export const Vacancy = ({ name, content, linkUrl }) => {
  return (
    <div>
      <div className={styles.root}>
        <p className={styles.name}>{name}</p>
        <h2 className={styles.description}>
          <AnimatedText>{content}</AnimatedText>
        </h2>

        <a href={linkUrl} className={styles.link}>
          Reply
        </a>
      </div>
    </div>
  );
};

Vacancy.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired
};
