import React from 'react';

import { Background } from '@components/Background';
import { Wrapper } from '@components/Wrapper';
import { Reference } from '@components/References/Reference';
import { Slider } from '@components/Slider';
import { HeaderTitle } from '@components/HeaderTitle';

import styles from './style.module.scss';

import { useBreakpoints } from '@utils/hooks';

import { references } from '@data';

export const References = () => {
  const { isLg } = useBreakpoints();

  return (
    <Background bg={isLg ? 'transparent' : 'white'} left={isLg} text="black">
      <Wrapper isHomePage>
        <div className={styles.root} id="refs">
          <div className={styles.left}>
            <HeaderTitle color={isLg ? '#fff' : '#000'}>
              <span>Our clients</span>
            </HeaderTitle>
          </div>
          <div className={styles.right}>
            <Slider
              pagesCount={references.length}
              renderContent={item => <Reference {...item} key={item.name} />}
              slides={references}
              isBlack={true}
              arrowsBottom
            />
          </div>
        </div>
      </Wrapper>
    </Background>
  );
};
