import React from 'react';
import { animated, useTrail } from 'react-spring';

import { Background } from '@components/Background';
import { Wrapper } from '@components/Wrapper';
import { HeaderTitle } from '@components/HeaderTitle';

import { useObserver } from '@utils/hooks/useObserver';
import { useMobileFadeIn } from '@utils/hooks/useAnimation';
import { useBreakpoints } from '@utils/hooks';

import { animationEffects } from '@constants';

import { stack } from '@data';

import styles from './style.module.scss';

export const Stack = () => {
  const [topRef] = useObserver({ threshold: 0.3 });
  const [bottomRef, isBottomVisible] = useObserver({ threshold: 0.2 });

  const { isLg } = useBreakpoints();

  const fadeIn = useMobileFadeIn(isBottomVisible, 0);
  const trail = useTrail(stack.length, animationEffects.fadeIn);

  return (
    <Background bg="white">
      <Wrapper isHomePage>
        <div id="stack">
          <div className={styles.titleWrapper} ref={topRef}>
            <HeaderTitle color="#000">
              <div className={styles.header}>
               Our stack
              </div>
            </HeaderTitle>
          </div>
          <div className={styles.itemsWrapper} ref={bottomRef}>
            {isLg &&
              trail.map((props, index) => (
                <animated.div key={stack[index].name} className={styles.imageWrapper} style={props}>
                  <img
                    style={{ width: stack[index].width, height: stack[index].height }}
                    alt={stack[index].name}
                    className={styles.image}
                    src={stack[index].image}
                  />
                </animated.div>
              ))}

            {!isLg &&
              stack.map(item => (
                <animated.div key={item.name} className={styles.imageWrapper} style={fadeIn}>
                  <img
                    style={{ width: item.width, height: item.height }}
                    alt={item.name}
                    className={styles.image}
                    src={item.image}
                  />
                </animated.div>
              ))}
          </div>
        </div>
      </Wrapper>
    </Background>
  );
};
