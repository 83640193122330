import { useRef, useState, useEffect } from 'react';

export const useObserver = (options, isAnimated = true) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      !isShown && setIsVisible(entry.isIntersecting);
    }, options);

    isVisible && isAnimated && setIsShown(isVisible);
    ref.current && observer.observe(ref.current);

    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, [ref, options]);

  return [ref, isVisible];
};
