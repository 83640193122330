import React from 'react';
import PropTypes from 'prop-types';

import { AnimatedText } from '@components/AnimatedText';

import styles from './style.module.scss';

export const Reference = ({ image, name, position, content }) => {
  return (
    <div>
      <div className={styles.root}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={image} alt="Reference photo" />
        </div>
        <h2 className={styles.name}>{name}</h2>
        <p>{position}</p>
        <div className={styles.line}/>
        <div className={styles.description}>
          <AnimatedText>{content}</AnimatedText>
        </div>
      </div>
    </div>
  );
};

Reference.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};
