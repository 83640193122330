import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

import { addClass } from '@utils';

import { useParallaxUp } from '@utils/hooks/useAnimation';

import styles from './style.module.scss';

export const Breadcrumbs = memo(({ currentPage, pages, isMapShown, setPage }) => {
  const { color } = pages[currentPage];
  const isAnimated = isMapShown && currentPage === 6;

  const parallaxUp = useParallaxUp(isAnimated, 0);

  const progress = useMemo(() => Math.ceil((100 / (pages.length - 1)) * currentPage), [currentPage]);

  return (
    <animated.div className={styles.root} style={parallaxUp}>
      <div className={styles.progress} style={{ background: `var(--color-accent)` }}>
        <div className={styles.bar} style={{ height: `${progress}%` }} />
      </div>
      {pages.map(({ label }, index) => {
        const isActive = index === currentPage;

        return (
          <div key={label} className={styles.item}>
            <div className={styles.circle}>
              <div className={styles.dot} style={{ background: `var(--color-accent)` }} />
              <div
                className={addClass(styles.outerCircle, styles.outerCircleActive, isActive)}
                style={{ background: isActive ? color : 'transparent' }}
              />
            </div>
            <span
              className={addClass(styles.name, styles.nameActive, isActive)}
              style={{ color: isActive ? color : `var(--color-accent)` }}
              onClick={() => setPage(index)}
            >
              {label}
            </span>
          </div>
        );
      })}
    </animated.div>
  );
});

Breadcrumbs.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  isMapShown: PropTypes.bool,
  setPage: PropTypes.func.isRequired
};

Breadcrumbs.displayName = 'Breadcrumbs';
